import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/PostList';
import { decodeEntities } from '../utils/htmlParse';
// import { KnowledgeHubTabs } from '../components/KnowledgeHubTabs';
import { KnowledgeHubHeader } from '../components/KnowledgeHubHeader';

const capitalizePathname = (slug) => {
  return slug.split('-').map(word => word.charAt(0).toUpperCase() + word.substr(1)).join(' ');
}

const getBreadCrumbs = (pathname) => {
  return pathname.replace(/^\/|\/$/g, '').split('/').map(slug => capitalizePathname(slug));
}

export default class Category extends Component {
  constructor(props) {
    super(props);
    const { data: { postsOnPage: { edges: posts } } } = this.props;
    this.state = {
      activePosts: posts
    };
  }

  searchItems = (searchTerm) => {
    const { data: { postsOnPage: { edges: posts } } } = this.props;
    const activePosts = posts.filter(post => post.node.title.toLowerCase().includes(searchTerm));
    return this.setState({
      activePosts
    });
  }

  render() {
    const { data, pageContext, location } = this.props;
    const { activePosts } = this.state;
    const { edges: allCategories } = data.allWordpressCategory;
    const { title: siteTitle, blogSlug } = data.site.siteMetadata;
    const { name: categoryName, slug: categorySlug } = pageContext;
    // const breadcrumbs = getBreadCrumbs(location.pathname);
    const pathPrefix = `/${blogSlug}`;
    const allCategoriesNoDummyPost = allCategories.filter(({node: category}) => {
      if (category.slug === 'uncategorised' && category.count === 1) return false;
      return true;
    });
    return (
      <Layout className="category-page-template">
        <SEO title={`${decodeEntities(categoryName)} | ${siteTitle}`} />
        <KnowledgeHubHeader
          activeTab="blog"
          location={location}
          searchItems={this.searchItems}
          isCategoryPage
          categoryName={categoryName}
        />
        <div className="wrap">
          <PostList
            posts={activePosts}
            pageContext={pageContext}
            pathPrefix={pathPrefix}
            categories={allCategoriesNoDummyPost}
            title={categoryName}
            activeFilter={categorySlug}
          />
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
        blogSlug
      }
    }
    allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "dummy-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
